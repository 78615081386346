<script setup lang="ts">
const { data } = useCustomFetch("/api/strapi/faq-section");

const props = defineProps<{
  items?: {
    id: number;
    question: string;
    answer: string;
  }[];
}>();

const items = computed(() =>
  Array.isArray(props.items) && props.items.length > 0
    ? props.items
    : data?.value?.items,
);
</script>

<template>
  <section v-if="data" class="faqblocks">
    <div class="container">
      <div style="position: relative">
        <div
          class="blureffect mainshadow"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        ></div>

        <div
          class="blureffect rightshadow"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        ></div>

        <div class="row">
          <div class="col-md-6">
            <h2 class="headline">
              {{ data.title }}
              <small>
                <NuxtLinkLocale
                  class="link"
                  :to="data?.link?.url"
                  :target="data?.link?.openInNewTab ? '_blank' : '_self'"
                >
                  <i
                    style="font-style: normal"
                    v-html="$mdRenderer.renderInline(data.link.name)"
                  ></i>
                </NuxtLinkLocale>
              </small>
            </h2>

            <img :src="$getStrapiImageSrc(data.image)" class="faqimg" />
          </div>

          <div class="col-md-6">
            <div id="accordionExample" class="accordion">
              <div
                v-for="(item, index) in items"
                :key="item.id"
                class="accordion-item"
              >
                <h2 :id="'heading' + index" class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse' + index"
                    aria-expanded="true"
                    :aria-controls="'collapse' + index"
                  >
                    {{ item.question }}
                  </button>
                </h2>
                <div
                  :id="'collapse' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'heading' + index"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    v-html="$mdRenderer.renderInline(item.answer)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <NuxtLinkLocale
            :to="data.bottomLink.url"
            :target="data.bottomLink.openInNewTab ? '_blank' : '_self'"
            class="btmlink"
          >
            {{ data.bottomLink.name }}
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
  </section>
</template>
